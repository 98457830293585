import React, { useState } from 'react';
import './navbar.css';
import logo from '../../Assets/logo.png';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <img src={logo} alt="Logo" className="logo" />
      <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
        <li><a href="#home" onClick={toggleMenu}>Home</a></li>
        <li><a href="#sobre" onClick={toggleMenu}>Sobre</a></li>
        <li><a href="#contato" onClick={toggleMenu}>Contatos</a></li>
      </ul>
      <div className="menu-icon" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;




