import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './Pages/Navbar/navbar';
import Home from './Pages/Home/home';
import Sobre from './Pages/Sobre/sobre';
import Contact from './Pages/Contato/contato';
import './App.css';

const App = () => { 
  return (
    <Router>
      <Navbar />
      <div id="home">
        <Home />
      </div>
      <div id="sobre">
        <Sobre />
      </div>
      <div id="contato">
        <Contact />
      </div>
    </Router>
  );
};

export default App;


