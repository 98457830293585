import React from 'react';
import './contato.css';
import { FaBuilding, FaAddressCard, FaEnvelope, FaPhone, FaUserTie, FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <div className="contact">
      <h1>Contatos</h1>
      <div className="contact-info">
        <div className="info-item">
          <FaBuilding className="icon" />
          <span>AMS Comércio e Empreendimentos Ltda</span>
        </div>
        <div className="info-item">
          <FaAddressCard className="icon" />
          <span>CNPJ: 55.775.598/0001-82</span>
        </div>
        <div className="info-item">
          <FaMapMarkerAlt className="icon" />
          <span>Endereço: 103 SUL RUA SO11 Lote 12 Sala 101-B 1º Andar, Palmas - TO CEP 77.015-034</span>
        </div>
        <div className="info-item">
          <FaEnvelope className="icon" />
          <span>Email: comercial@amsempreendimento.com.br</span>
        </div>
        <div className="info-item">
          <FaPhone className="icon" />
          <span>Fone: (63) 981581038</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;


