import React from 'react';
import './home.css';
import imagem1 from '../../Assets/imagem1.png';

const Home = () => {
  return (
    <div className="home">
      <div className="rectangle">
        <img src={imagem1} alt="Central Image" />
      </div>
    </div>
  );
};

export default Home;


