import React from 'react';
import './sobre.css';
import empresaImage from '../../Assets/bandeira.png';

const Sobre = () => {
  return (
    <div className="about">
      <div className="about-card">
        <h2>Sobre a Empresa</h2>
        <p>
          A AMS Comércio e Empreendimentos é uma empresa inovadora, situada em Palmas, Tocantins, especializada na área de tecnologia voltada para processos de licitação. Com um compromisso sólido com a excelência e a transparência, a AMS se destaca por oferecer soluções eficazes e adaptáveis que atendem às necessidades de nossos clientes no setor público.
        </p>
        <p>
          Nosso foco é otimizar e desburocratizar os procedimentos licitatórios, garantindo agilidade, segurança e compliance em todas as etapas. Através de tecnologia de ponta e uma equipe altamente capacitada, buscamos transformar a forma como as empresas e instituições se relacionam com os processos de compra e contratação.
        </p>
        <p>
          Além disso, somos especializados na revenda de equipamentos de Tecnologia da Informação por meio de processos licitatórios. Pretendemos nos tornar uma empresa parceira de revendas específicas de equipamentos e soluções de TI, oferecendo produtos de alta qualidade e suporte técnico especializado.
        </p>
        <p>
          Na AMS, acreditamos que a inovação é a chave para o sucesso. Estamos constantemente atualizando nossos métodos e ferramentas para oferecer o melhor suporte aos nossos clientes, contribuindo para um ambiente de negócios mais justo e eficiente.
        </p>
        <p>
          Junte-se a nós e descubra como podemos impulsionar seu sucesso em licitações!
        </p>
      </div>
      <img src={empresaImage} alt="Empresa" className="about-image" />
    </div>
  );
};

export default Sobre;

